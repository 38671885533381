/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Banner, Section, IntroTextContainer, TabsContainerV2, TabsContainerTestimonial, Support, SubSupport, ResponsiveTestimonialTab, ButtonBookDemo, MenuLinkss, ButtonBookDemoo, CoreList, MenuListV2Main2, CurveWrapper, ProductWrapper, VideoButton, CustomList, CoreWrapper, ButtonLearnMoree, RatingWrapper, Wrapper, MenuListV2Main, CustomListItemV2Main, RatingsContainers, MainWrapper, GlobalMainWrapper, ShowLogos, CompanyMainWrapper, WellBeingMainWrapper, WellBeing, PosterImage, ButtonGetStarted,/*BannerCurve*/ButtonBookDemoNew, AboutWolibaWrapper, WellnessWrapper, EngagementWrapper, EngagementTextContainer, RewardWrapper, RewardTextContainer, RecognitionWrapper, WheelWrapperNew, TopCard, EngagementImageContainer, RewardImageContainer, DemoText } from './styles';
import { isValidEmail } from '../../utils/methods';
import { MenuLinks } from '../NavigationBarV2/styles';
import { saveContactUsInfo } from '../../redux/actions';
import testimonialData from './TestimonialData.json'
import coreData from './CoreFeature.json'
import Data from './Product.json'
const DemoPopup = React.lazy(() => import('../WhyAmigoCommon/DemoPopup'));
import { COMPANY_NAME } from '../../utils/config';
const ContactPopup = React.lazy(() => import('../common/ContactPopup'));
import LazyImage from '../common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';
import { Suspense } from 'react';
import Waiting from '../Waiting';
import { ButtonWatchVideo } from '../CommunityRecognition/style';
// import { identity } from 'lodash';

let interval1;
let count = 0;
class MainDashboard extends Component {
  constructor() {
    super();
    this.state = {
      mobileViewStatus: window.innerWidth <= 500,
      active: '',
      email: '',
      showVideo: false,
      showContact:false,
      selectedTab: 0,
      selectedSubTab: 0,
      selectedProTab: 0,
      selectedCoreTab: 0,
      currentDiv: [true, false, false, false, false, false],
      productData: JSON.parse(JSON.stringify(Data)),
      tabArray: ["Insurance", "Banking", "Technology", "Healthcare", "Civil Engineering"],
      productHead: [Data[0].head, Data[1].head, Data[2].head, Data[3].head],
      productDesc: [Data[0].desc, Data[1].desc, Data[2].desc, Data[3].desc],
      productImage: [Data[0].img, Data[1].img, Data[2].img, Data[3].img],
      productColor: [Data[0].color, Data[1].color, Data[2].color, Data[3].color],
      productBackGroundColor: [Data[0].bgColor, Data[1].bgColor, Data[2].bgColor, Data[3].bgColor],
      coreButtonText: [coreData[0].buttonText, coreData[1].buttonText, coreData[2].buttonText, coreData[3].buttonText, coreData[4].buttonText, coreData[5].buttonText],
      coreInfo2: [coreData[0].data2, coreData[1].data2, coreData[2].data2, coreData[3].data2, coreData[4].data2, coreData[5].data2],
      coreInfo: [coreData[0].data, coreData[1].data, coreData[2].data, coreData[3].data, coreData[4].data, coreData[5].data],
      coreHead: [coreData[0].head, coreData[1].head, coreData[2].head, coreData[3].head, coreData[4].head, coreData[5].head],
      coreName: [coreData[0].name, coreData[1].name, coreData[2].name, coreData[3].name, coreData[4].name, coreData[5].name],
      coreImage: [coreData[0].img, coreData[1].img, coreData[2].img, coreData[3].img, coreData[4].img, coreData[5].img],
      ratingImage: [testimonialData[0].imageURL, testimonialData[1].imageURL, testimonialData[2].imageURL, testimonialData[3].imageURL, testimonialData[4].imageURL],
      ratingData: [testimonialData[0].data, testimonialData[1].data, testimonialData[2].data, testimonialData[3].data, testimonialData[4].data],
      ratingName: [testimonialData[0].head, testimonialData[1].head, testimonialData[2].head, testimonialData[3].head, testimonialData[4].head],
      ratingMain: [testimonialData[0].main, testimonialData[1].main, testimonialData[2].main, testimonialData[3].main, testimonialData[4].main],
      showtab: 1,
      wheelTitle:"wellness",
      wheelDescription: 'Creating a truly high-performing culture requires performance, recognition, and feedback to come together to amplify one another in a powerful employee experience platform.',
      wheelData: [
        
        {
          'title': 'engagement',
          'description': "Our employee engagement platform increases productivity, boosts innovation and increases retention so your company can thrive.",
          'selected': false,
         
        },
        {
          'title': 'rewards',
          'description': "Boost employee engagement and retention with a modern rewards program that helps employees feel valued and accepted. ",
          'selected': false,
        
        },
        {
          'title': 'recognition',
          'description': "Acknowledge your people for who they are and what they do because happy employees are up to 20% more productive than unhappy employees.",
          'selected': false,
          
        },
        {
          'title': 'coaching',
          'description': "Go a step above and beyond for employees with live event coaching and coach-led challenges that provide motivation and accountability.",
          'selected': false,
          
        },
        {
          'title': 'enhance employee culture and company experience',
          'description': "Create a truly high-performing culture with our powerful platform that offers engagement, rewards, recognition and coaching.",
          'selected': false,
          
        },
        {
          'title': 'wellness',
          'description': "Creating a truly high-performing culture requires performance, recognition, and feedback to come together to amplify one another in a powerful employee experience platform. ",
          'selected': false,
          
        }
      ]
    }
  }


  componentDidMount() {
    this.setState({ active: 'Insurance' })
    const { wheelData } = this.state;

    interval1 = setInterval(() => {
      this.setState({ wheelTitle: wheelData[count] && wheelData[count].title, wheelDescription: wheelData[count] && wheelData[count].description})
      count += 1
      if (count > 5) {
        count = 0
      }
    }, 3000);

  }

  onSubmit = () => {
    const { saveContactUsInfo, history } = this.props;
    const { email } = this.state;
    if (email.trim() !== '' && isValidEmail(email)) {
      saveContactUsInfo('email', email);
      history.push('/contact');
    }
  };

  changeModal = () => {
    this.setState({ showVideo: !this.state.showVideo });
  };
  changeContactModal = () => {
    this.setState({ showContact: !this.state.showContact });
  };

  redirection = () => {
    const { history } = this.props;
    history.push('/demo');
  }

  topSection = () => (
    <Section firstChild margin="0">
      <IntroTextContainer>
        <h1>
          It’s What All {this.state.mobileViewStatus ? <br /> : ''}  
          Employees Want: {this.state.mobileViewStatus ? <br /> : ''} 
          <span style={{ color: '#F6797D' }}>Work Life Balance</span>
          {/* Create A Culture {this.state.mobileViewStatus ? <br /> : ''} Of {this.state.mobileViewStatus ? '' : <br />}<span style={{ color: '#F6797D' }}>Wellbeing</span> Even When {this.state.mobileViewStatus ? ' ' : <br />}Teams Are {this.state.mobileViewStatus ? <br /> : ''}  Remote */}
        </h1>
        <LazyImage div={"divider"} src={ImageUrl + "/images/HomePageV2/Rectangle.png"}/>
        <p>
          Build a winning workplace with our  all-in-one {this.state.mobileViewStatus ? '' : <br />}
          wellness and  employee experience platform. {this.state.mobileViewStatus ? '' : <br />}
        </p>
        <div className='mobile-top-paragraph'>
          Woliba helps organizations enhance
          their employee experience with one 
          comprehensive platform to easily 
          manage wellbeing, engagement, 
          recognition, rewards, and data and 
          analytics.
        </div>
        <div className="wrap">
          <ButtonBookDemoo onClick={() => this.changeModal()} noMargin={1} style={{display:'flex', alignItems:'center', justifyContent:'center'}}><LazyImage div='white_triangle' src={ImageUrl + '/images/white_triangle.svg'}/>SEE WOLIBA</ButtonBookDemoo>
          
          <ButtonLearnMoree onClick={()=> this.props.history.push('/contact')} noMargin={1}>REQUEST A DEMO</ButtonLearnMoree> 
          {/* <ButtonLearnMoree onClick={() => {this.changeContactModal();}} noMargin={1}>REQUEST A DEMO</ButtonLearnMoree>  */}
          <VideoButton onClick={() => this.changeModal()}>
            <i className="fa fa-play" />
            QUICK INTRO VIDEO
          </VideoButton>
        </div>
      </IntroTextContainer>
      <PosterImage>
        {COMPANY_NAME == 'Woliba' ?
          <picture>
            <source srcSet={ImageUrl + "/images/HomePageV2/Home_Page_Banner.webp"} type="image/png" />
            <LazyImage src={ImageUrl + "/images/HomePageV2/Home_Page_Banner.webp"} title="Poster Image" />
          </picture> : <picture>
            <source srcSet={ImageUrl + "/images/HomePageV2/graphic-iphone.png"} type="image/png" />
            <LazyImage src={ImageUrl + "/images/HomePageV2/graphic-iphone.png"} title="Poster Image" />
          </picture>}
      </PosterImage>
    </Section>
  )

  handleSubResponsiveTabs = (resIndex) => {
    const { currentDiv } = this.state;
    let array = currentDiv;
    array.forEach((data, index) => {
      array[index] = false;
    })
    array[resIndex] = true;
    this.setState({ selectedCoreTab: resIndex });
    this.setState({ currentDiv: array });
    if (resIndex === this.state.selectedCoreTab) {
      this.setState({ showtab: !this.state.showtab });
    }
  }

  handleProduct = (resIndex) => {
    const { productData } = this.state;
    let array = productData;
    array.forEach((info) => {
      info.status = 0;
    })
    array[resIndex].status = 1;
    this.setState({ selectedProTab: resIndex });
    this.setState({ productData: array });
  }

  handleWheelClick = (id) => {
    const { wheelData } = this.state;

    clearInterval(interval1)
    this.setState({ wheelTitle: wheelData[id] && wheelData[id].title, wheelDescription: wheelData[id] && wheelData[id].description})
  }

  handleLearnMore = () => {
    this.state.wheelTitle == 'engagement' ? this.props.history.push('/engagement') :
      this.state.wheelTitle == 'rewards' ? this.props.history.push('/employee-rewards') :
        this.state.wheelTitle == 'recognition' ? this.props.history.push('/employee-recognition')
          : this.props.history.push('/wellness')
  }

  scurve = () => (
    <svg width="1055" height="269" viewBox="0 0 1055 269" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M64.9997 1.5C7.83308 55.3216 5.99979 203.699 519 111.924C676 83.8373 1146 47.6328 996.5 267.5" strokeWidth="2" strokeLinejoin="round" />
      <path d="M3.9998 35C-3.16686 59.0831 -3.2002 113.165 53.9998 136.828M937 84.9441C978.666 87.5302 1060.3 113.262 1053.5 195.5" strokeWidth="2" />
    </svg>
  );
  middleSection = () => (
    <MainWrapper bgResponsiveColor={this.state.productBackGroundColor[this.state.selectedProTab]} fontResponsiveColor={this.state.productColor[this.state.selectedProTab]} customWith={this.state.selectedProTab === 0 ? 1 : 0}>
      <div>
        <h1>Meet Woliba</h1>
        <p>Discover How People Leaders Everywhere<br />
          Are Unifying Their Approach For Wellbeing<br /> And Engagement.
        </p>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </div>
      <div>
        <div>
          <img src={this.state.productImage[this.state.selectedProTab]} />
        </div>
        <div className="responsive-content">
          <h1>{this.state.productHead[this.state.selectedProTab]}</h1>
          <p>{this.state.productDesc[this.state.selectedProTab]}</p>
        </div>
        <div>
          {
            this.state.productData.length > 0 && this.state.productData.map((data, index) => (
              <ProductWrapper key={index} imageWidth={data.imgWidth} show={index} selected={data.status} fontColor={data.color} bgColor={data.bgColor} backColor={data.backColor} onClick={() => this.handleProduct(index)}>
                <div className="bullet-child">
                  <div className="bullet">
                    <div className="inner-bullet">
                      {
                        data.status === 1 && <img src={data.icon} />
                      }
                    </div>
                  </div>
                  <div className="vertical-line" >
                  </div>
                </div>
                <div className="content">
                  <h1>{data.head}</h1>
                  <p>{data.desc}</p>
                </div>
              </ProductWrapper>
            ))
          }
        </div>
      </div>
    </MainWrapper>
  )

  globalPositionSection = () => (
    <GlobalMainWrapper>
      <div>
        <h2>We are consistently ranked as a leader</h2>
      </div>
      <div>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/CorporateWellness_HighPerformer_HighPerformer.svg"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/CorporateWellness_EasiestSetup_Mid-Market_EaseOfSetup.svg"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/CorporateWellness_BestRelationship_Mid-Market_Total.svg"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/EmployeeRecognition_EasiestAdmin_EaseOfAdmin.svg"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/CorporateWellness_BestUsability_Mid-Market_Total.svg"} width="70%"/>
      </div>
    </GlobalMainWrapper>
  )

  companySection = () => (
    <CompanyMainWrapper>
      <div>
        <p>Trusted By Brokers and Global Organizations to promote culture and wellbeing</p>
      </div>
      <ShowLogos >
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/alera.svg"}/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/dhx.svg"}/>
        {!this.state.mobileViewStatus ? <LazyImage src={ImageUrl + "/images/NewHomePageV2/mastercontrol.svg"}/> : <LazyImage src={ImageUrl + "/images/NewHomePageV2/Laerdal.png"}/>}
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/pure.svg"}/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/laerdal.svg"}/>
      </ShowLogos>
    </CompanyMainWrapper>
  )

  aboutWolibaSection = () => (
    <AboutWolibaWrapper>
      <div className='About-uppersection'>
        <p className='section-heading'>About <span style={{ fontFamily: 'Rubik-Medium' }}>Woliba</span></p>
        <h2 className='middle-info'>
          All-in-One <span style={{ color: '#69C2FF' }}>Wellness Solution</span> built to empower<br />
          Your employees
        </h2>
        <p className='last-info'>
          Powerful solutions in one platform to keep your culture & workplace connected.
        </p>
      </div>
      <WheelWrapperNew wheelBtnBg={this.state.wheelTitle}>
        <div className='wheel-section'>
          <div className='outer-circle'>
            <div className="wrap">
              <LazyImage src={ImageUrl + "/images/engagementArc.svg"} div={"quart quart1"} onClick={() => this.handleWheelClick(0)}/>
              <LazyImage src={ImageUrl + "/images/rewardsArc.svg"} div={"quart quart2"} onClick={() => this.handleWheelClick(1)}/>
              <LazyImage src={ImageUrl + "/images/coachingArc.svg"} div={"quart quart3"} onClick={() => this.handleWheelClick(3)}/>
              <LazyImage src={ImageUrl + "/images/recognitionArc.svg"} div={"quart quart4"} onClick={() => this.handleWheelClick(2)}/>
              <div className="center">
                <LazyImage className="woliba-icon" src={ImageUrl + "/images/wolibaWheelIcon.svg"} div={"woliba-icon-inner-wrapper quart-icon-wrapper"} onClick={() => this.handleWheelClick(5)}/>
              </div>
            </div>
          </div>
        </div>
        <div className='content-section'>
          <h3 className="wheel-content-header" style={{margin:0}}>
            {this.state.wheelTitle}
          </h3>
          <p className='wheel-content-text'>
            {this.state.wheelDescription}
            {/* Creating a truly high-performing culture requires {this.state.mobileViewStatus ? '' : <br/>}
            performance, recognition, and feedback to come{this.state.mobileViewStatus ? '' : <br/>}
            together to amplify one another in a powerful{this.state.mobileViewStatus ? '' : <br/>}
            employee experience platform. */}
          </p>
          {this.state.wheelTitle == 'coaching' ? "" : <div className='learnMore-btn'
            onClick={this.handleLearnMore}
          >Learn More</div>}
        </div>
      </WheelWrapperNew>
    </AboutWolibaWrapper>
  )

  wellnessSection = () => (
    <WellnessWrapper>
      <div className='wellness-uppersection'>
        <p className='wellness-heading'>Woliba <span style={{ fontFamily: 'Rubik-Medium' }}>Wellness</span></p>
        <h2 className='middle-text'>
          <span style={{ color: '#69C2FF' }}>Wellness</span> Resources For Your Global Team
        </h2>
        <p className='last-text'>
          We take a holistic approach to workplace and employee wellness offering a diverse range of{this.state.mobileViewStatus ? '' : <br/>}
          resources focusing on all areas including mental and physical wellbeing.
        </p>
        <div style={{display:'flex',gap:'5%',alignItems:'center'}}>
          <div className='explore-well' onClick={() => this.props.history.push('/wellness')}>Explore Wellness</div>
          <a href='https://woliba.navattic.com/at4p060h' target='blank' style={{textDecoration:"none",marginTop:'40px'}}><ButtonWatchVideo style={{display:'flex',alignItems:"center",justifyContent:"center",color:"#69c2ff",border:"2px solid #69c2ff",width:'250px'}}>
            <svg width="21" height="24" viewBox="0 0 136 153" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M129.381 65.0255C138.206 70.1255 138.206 82.8753 129.381 87.9752L20.1667 151.086C11.3413 156.186 0.30957 149.812 0.30957 139.612L0.309577 13.3884C0.309577 3.18855 11.3413 -3.18634 20.1667 1.91359L129.381 65.0255Z" fill="#69c2ff"/>
            </svg>
            <div style={{marginLeft:'8%'}}>Take a Tour</div></ButtonWatchVideo></a>
        </div>  
        <LazyImage className='wellness-things' src={ImageUrl + "/images/HomePageV2/WolibaWellnessImageNew.webp"}/>
        <LazyImage className='wellness-things-mobile' src={ImageUrl + "/images/HomePageV2/MobileWellnessImage.webp"}/>
        {/* <img className='wellness-things-mobile' src='/public/images/HomePageV2/MobileWellnessImage.webp'></img> */}
      </div>
      <div className='continuous-wellbeing'>
        <h2 className='wellbeing-topline'>Continuous well-being, delivered in real time</h2>
        <p className='middle-para'>
          Our platform puts behavior change solutions at your fingertips. We embed well-being directly in the<br />
          daily workflow to meet your people where they are with real-time stress-reducing tools, inspirational<br />
          storytelling, and science-backed Microsteps that help them build better habits.
        </p>
        <div className='button-wrapper'>
          {/* <div className='get-start' onClick={() => this.props.history.push('/pricing')}>Get Started</div> */}
          <div className='request-demo' onClick={() => this.props.history.push('/contact')}>Request a Demo</div>
        </div>
      </div>
    </WellnessWrapper>
  )

  engagementSection = () => (
    <EngagementWrapper>
      <EngagementTextContainer>
        <p className='engagement-heading'>Woliba <span style={{ fontFamily: 'Rubik-Medium' }}>Engagement</span></p>
        <h2 className='middle-engagement-text'>
          Check real-time<br />
          <span style={{ color: '#69C2FF' }}>Engagement</span> of your<br />
          organization.
        </h2>
        <p className='last-engagement-text'>
          Discover how People Leaders everywhere{this.state.mobileViewStatus ? '' : <br/>}
          are unifying their approach for wellbeing{this.state.mobileViewStatus ? '' : <br/>}
          and engagement.
        </p>
        <div className='explore-btn' onClick={() => this.props.history.push('/engagement')} >Explore Engagement</div>
      </EngagementTextContainer>
      <EngagementImageContainer>
        <LazyImage className='engagement-img' src={ImageUrl + "/images/HomePageV2/Engagment_Image.webp"}/>
        <LazyImage className='engagement-mobile-img' src={ImageUrl + "/images/Engagement_Mobile_img.png"}/>
      </EngagementImageContainer>
    </EngagementWrapper>
  )

  rewardSection = () => (
    <RewardWrapper>
      <RewardImageContainer>
        <LazyImage width={'670px'} className='reward-img' src={ImageUrl + "/images/HomePageV2/Rewards_Image.webp"}/>
        <LazyImage className='reward-mobile-img' src={ImageUrl + "/images/Reward_Mobile_img.png"}/>
      </RewardImageContainer>
      <RewardTextContainer>
        <p className='reward-heading'>Woliba <span style={{ fontFamily: 'Rubik-Medium' }}>Rewards</span></p>
        <h2 className='middle-reward-text'>
          Motivate employees<br />
          with customizable and<br />
          inclusive <span style={{ color: '#69C2FF' }}>Rewards</span>
        </h2>
        <p className='last-reward-text'>
          Offer a gamified wellness and engagement{this.state.mobileViewStatus ? '' : <br/>} 
          app that incentivizes employees to participate{this.state.mobileViewStatus ? '' : <br/>} 
          in company initiatives.
        </p>
        <div className='explore-btn2' onClick={() => this.props.history.push('/employee-rewards')}>Explore Rewards</div>
      </RewardTextContainer>
    </RewardWrapper>
  )

  recognitionSection = () => (
    <RecognitionWrapper>
      <div className='recognition-uppersection'>
        <p className='recognition-heading'>Woliba <span style={{ fontFamily: 'Rubik-Medium' }}>Recognition</span></p>
        <h2 className='middle-recognition-text'>
          <span style={{ color: '#69C2FF' }}>Recognition</span> platform that speaks To your culture.
        </h2>
        <p className='last-recognition-text'>
          Discover how People Leaders everywhere are unifying their approach for wellbeing and engagement.
        </p>
        <div style={{display:'flex',gap:'5%',alignItems:'center'}}>
          <div className='explore-recognition' onClick={() => this.props.history.push('/employee-recognition')}>Explore Recognition</div>
          <a href='https://woliba.navattic.com/dq4p0y17' target='blank' style={{textDecoration:"none",marginTop:'40px'}}><ButtonWatchVideo style={{display:'flex',alignItems:"center",justifyContent:"center",color:"#69c2ff",border:"2px solid #69c2ff",width:'250px'}}>
            <svg width="21" height="24" viewBox="0 0 136 153" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M129.381 65.0255C138.206 70.1255 138.206 82.8753 129.381 87.9752L20.1667 151.086C11.3413 156.186 0.30957 149.812 0.30957 139.612L0.309577 13.3884C0.309577 3.18855 11.3413 -3.18634 20.1667 1.91359L129.381 65.0255Z" fill="#69c2ff"/>
            </svg>
            <div style={{marginLeft:'8%'}}>Take a Tour</div></ButtonWatchVideo></a>
        </div>  
        <LazyImage className='recognition-things' src={ImageUrl + "/images/HomePageV2/Recognition_Image.webp"}/>
        <LazyImage className='recognition-mobile-things' src={ImageUrl + "/images/recognition_mobile_things_img.png"}/>
      </div>
    </RecognitionWrapper>
  )

  TopCardSection = () => (
    <TopCard>
      <div className='topcard-demo-wrapper'>
        <div className='topcard-demo'>
          <div className='topcard-pic'>
            <img className='enter-img' src='/public/images/request_a_demo.svg'></img>
          </div>
          <div className='topcard-content'>
            <div className='upper-content'>
              Don’t ready to chat Yet?
            </div>
            <div className='lower-content'>
              <p>See how Woliba can help you manage all of your</p>

              <p>employee wellness and Welbeing in one place And</p>

              <p>easily keep all your employee health in control</p>
            </div>
            <div className='lower-mob-content'>
              <p>See how Woliba can help you manage all of your

                employee wellness and Welbeing in one place And

                easily keep all your employee health in control</p>
            </div>
          </div>
          <button className='demo-btn' onClick={() => this.redirection()}>
            <div className='watch-text' >Watch Recorded Demo</div>
          </button>
        </div>
      </div>
    </TopCard>
  )

  wellBeingSection2 = () => (
    <WellBeingMainWrapper>
      <div>
        <img src="/public/images/NewHomePageV2/Group194.png"></img>
      </div>
      <div>
        <div>
          <h1>Strengthen Company</h1>
          <h1>Culture Enhance </h1>
          <h1>Workplace Wellbeing</h1>
          <img src="/public/images/HomePageV2/Rectangle.png" />
          <Support>
            Support your team with the most comprehensive suite
            of wellness resources. Need support managing your
            wellness program?
          </Support>
          <SubSupport>No worries, we got you covered!</SubSupport>
          <ButtonBookDemoNew customWith={1} onClick={() => this.props.history.push('/contact')} noMargin={1}>REQUEST A DEMO </ButtonBookDemoNew>
        </div>
      </div>
    </WellBeingMainWrapper>
  )

  wellBeingSection = () => (
    <WellBeing background={"rgb(253, 113, 117)"}>
      <div style={{background:'rgb(253, 113, 117)'}}>
        <div>
          <p style={{marginBottom:0}} >Want to see the full <br />
            Woliba experience?</p>
          <DemoText>
            Request a demo to meet with one of our<br />
            wellness specialists.
          </DemoText>
          <ButtonGetStarted display={'block'} onClick={() => this.props.history.push('/contact')}>Schedule A Live Demo</ButtonGetStarted>
        </div>
      </div>

      <LazyImage src={ImageUrl + "/images/HomePageV2/Schedule_a_demo.webp"} />

    </WellBeing>
  )

  ratingsUI = () => (
    <RatingWrapper>
      <Wrapper>
        <h2>Loved by Clients</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </Wrapper>
      <RatingsContainers>
        <LazyImage div={"image"} src={ImageUrl + this.state.ratingImage[this.state.selectedTab]} alt={"rating"} />
        <LazyImage div={"ratingStars"} src={ImageUrl + "/images/HomePageV2/ratingStars.png"} alt={"rating"} />
        <div className={"ratingDescription"}>{this.state.ratingData[this.state.selectedTab]}</div>
        <div className={"ratingBy"}>{this.state.ratingName[this.state.selectedTab]} <span>{this.state.ratingMain[this.state.selectedTab]}</span></div>
      </RatingsContainers>
    </RatingWrapper>
  );

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  }

  handleSubTabs = (index) => {
    this.setState({ selectedSubTab: index });
  }
  renderMenuList = () => {
    let TabArray = [
      {
        tabName: "Healthcare",
        image: "/path/",
        text: "Healthcare data"
      },
      {
        tabName: "Technology",
        image: "/path/",
        text: "Technology Data"
      },
      {
        tabName: "Civil Engineering",
        image: "/path/",
        text: "Civil Engineering data"
      },
      {
        tabName: "Banking",
        image: "/path/",
        text: "Banking data"
      },
      {
        tabName: "Insurance",
        image: "/path/",
        text: "Insurance data"
      }
    ]

    return (
      <TabsContainerTestimonial>
        <MenuListV2Main>
          {TabArray.map((data, index) => (
            <CustomListItemV2Main showTab={true} active={this.state.selectedTab === index} key={index} onClick={() => this.handleTabs(index)} color='1'>
              <MenuLinks>{data.tabName}</MenuLinks>
            </CustomListItemV2Main>
          ))}
        </MenuListV2Main>
      </TabsContainerTestimonial>)
  };

  wellnessPageRedirection = (index) => {
    if (index === 0) {
      this.props.history.push('/wellness-challenges')
    }
    if (index === 1) {
      this.props.history.push('/nutrition-recipes')
    }
    if (index === 3) {
      this.props.history.push('/wellness-education')
    }
    if (index === 2) {
      this.props.history.push('/fitness-videos')
    }
    if (index === 4 || index === 5) {
      this.props.history.push('/contact')
    }
  }

  coreFeature = () => {
    let SubTabArray = [
      {
        tabName: "Challenges",
        image: "/path/",
      },
      {
        tabName: "Recipes",
        image: "/path/",
      },
      {
        tabName: "Fitness",
        image: "/path/",
      },
      {
        tabName: "Education",
        image: "/path/",
      }, {
        tabName: "Social",
        image: "/path/",
      }, {
        tabName: "Assessments",
        image: "/path/",
      }
    ]
    return (
      <CoreWrapper>
        <div>
          <h1>wellness Resources Employees Love</h1>
          <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
        </div>
        <div>
          <TabsContainerV2>
            <MenuListV2Main2>
              {SubTabArray.map((data, index) => (
                <CustomList showTab={true} active={this.state.selectedSubTab === index} key={index} onClick={() => this.handleSubTabs(index)}>
                  <MenuLinkss>{data.tabName}</MenuLinkss>
                </CustomList>
              ))}
            </MenuListV2Main2>
          </TabsContainerV2>
        </div>
        <div>
          <div>
            <h1>{this.state.coreName[this.state.selectedSubTab]}</h1>
            <h2>{this.state.coreHead[this.state.selectedSubTab]}</h2>
            <img src="/public/images/HomePageV2/Rectangle2.png" />
            <div>
              <p>{this.state.coreInfo[this.state.selectedSubTab]}</p>
              {/* <br/> */}
              <p>{this.state.coreInfo2[this.state.selectedSubTab]}</p>
            </div>
            {this.state.selectedSubTab === 4 || this.state.selectedSubTab === 5 ? (<span></span>) : (<ButtonBookDemo onClick={() => this.wellnessPageRedirection(this.state.selectedSubTab)} noMargin={1}>{this.state.coreButtonText[this.state.selectedSubTab]}</ButtonBookDemo>)}
          </div>
          <div><img src={this.state.coreImage[this.state.selectedSubTab]} /></div>
        </div>
        <div className="responsive-core">
          {SubTabArray.map((data, index) => (
            <CoreList active={this.state.selectedCoreTab === index && this.state.showtab ? 1 : 0} key={index} onClick={() => this.handleSubResponsiveTabs(index)}>
              <div style={{alignItems:"center"}}>
                <h1>{data.tabName}</h1>
                <LazyImage src={ImageUrl + "/images/HomePageV2/down.png"}/> 
              </div>
              {this.state.currentDiv[index] && this.state.showtab &&
                <div className="selectedCoreContent">
                  <div>
                    <h2>{this.state.coreHead[this.state.selectedCoreTab]}</h2>
                    <img src="/public/images/HomePageV2/Rectangle.png" />
                    <div>
                      <p>{this.state.coreInfo[this.state.selectedCoreTab]}</p>
                      <br />
                      <p>{this.state.coreInfo2[this.state.selectedCoreTab]}</p>
                    </div>
                    {this.state.selectedSubTab === 4 || this.state.selectedSubTab === 5 ? (<span></span>) : (<ButtonBookDemo onClick={() => this.wellnessPageRedirection(this.state.selectedCoreTab)} noMargin={1}>{this.state.coreButtonText[this.state.selectedCoreTab]}</ButtonBookDemo>)}
                  </div>
                  <div><img src={this.state.coreImage[this.state.selectedCoreTab]} /></div>
                </div>
              }
              <LazyImage div={"coreDivider"} src={ImageUrl + "/images/HomePageV2/CoreDivider.png"}/>
            </CoreList>
          ))}
        </div>
      </CoreWrapper>)
  };

  showCurve = () => (
    <CurveWrapper>
      {this.scurve()}
    </CurveWrapper>
  )

  decreaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab - 1;
    this.setState({ selectedTab: temp })
  }

  increaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab + 1;
    this.setState({ selectedTab: temp })
  }
  renderResponsiveTab = () => (
    <ResponsiveTestimonialTab>
      {this.state.selectedTab > 0 ? 
        this.state.selectedTab > 0 && <LazyImage div="leftArrow" onClick={() => this.decreaseIndex()} src={ImageUrl + "/images/HomePageV2/leftArrow.png"} />
        : <div className="leftArrow"></div>}
      <div>
        <p>
          {
            this.state.tabArray[this.state.selectedTab]
          }
        </p>
      </div>
      {this.state.selectedTab < 4 && 
          this.state.selectedTab < 4 && <LazyImage div="rightArrow" onClick={() => this.increaseIndex()} src={ImageUrl + "/images/HomePageV2/rightArrow.png"} /> }
    </ResponsiveTestimonialTab>
  );
  render() {
    const { showVideo, showContact } = this.state;
    return (
      <React.Fragment>
        <Banner>
          {this.topSection()}
        </Banner>
        {this.companySection()}
        {this.aboutWolibaSection()}
        {this.wellnessSection()}
        {this.engagementSection()}
        {this.rewardSection()}
        {this.recognitionSection()}
        {/* {this.TopCardSection()} */}
        {/* {this.middleSection()} */}
        {this.ratingsUI()}
        {this.renderMenuList()}
        {this.renderResponsiveTab()}
        {this.globalPositionSection()}
        
        {/* {this.coreFeature()} */}
        {/* <BannerCurve>
            {this.wellBeingSection2()}
          </BannerCurve> */}
        {this.wellBeingSection()}
        {showVideo && <Suspense fallback={<Waiting/>}><DemoPopup showModal={showVideo} onClose={this.changeModal} videoUrl={"https://vimeo.com/808590329"} VideoTitle={"Woliba Wellness"} /></Suspense>}
        {showContact && <Suspense fallback={<Waiting/>}><ContactPopup showModal={showContact} onClose={this.changeContactModal} /></Suspense>}
      </React.Fragment>
    );
  }
}

MainDashboard.propTypes = {
  history: PropTypes.object.isRequired,
  saveContactUsInfo: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  saveContactUsInfo: (name, value) => dispatch(saveContactUsInfo(name, value))
});

export default connect(null, mapDispatchToProps)(MainDashboard);